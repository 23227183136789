.repeatCheck {
  display: flex;
  align-items: center;
}

.selects {
  display: flex;
  padding-top: 0.5rem;
}

.select {
  display: flex;
  justify-content: center;
  flex-grow: 2;
  padding-bottom: 1rem;
}

.selects > .select + * {
  margin-left: 0.8rem;
}
